import * as React from "react"
import { Container } from "react-bootstrap"
import { Link, navigate } from "@reach/router";

// Components
import Divider from "../Divider";
// import { menuLinks } from "../common/menuUtils";

// Styles
import "./BreadCrumbs.scss"


const BreadCrumbs = ( {  menu_data } ) => {

    //console.log("Menu_Data", menu_data);


    return (
        <div className="bread-crumbs-wrapper">
            <Container>
                <div className="bread-crumbs-content">
                    <Link to={"/"} className="">
                        Home
                    </Link>
                    <Divider delimiter=">" />

                    {(menu_data?.Parent_Menu !== null) && <>
                        <Link to={`/${menu_data?.Parent_Menu?.Primary_URL}/`} className="">
                            {menu_data?.Parent_Menu?.Page_Name}
                        </Link>
                        <Divider delimiter=">" />
                        </>
                    }

                    {(menu_data?.Parent_Menu !== null && menu_data?.Sub_Menu !== null) && <>
                        <Link to={`/${menu_data?.Parent_Menu?.Primary_URL}/${menu_data?.Sub_Menu?.Primary_URL}/`} className="">
                            {menu_data?.Sub_Menu?.Page_Name}
                        </Link>
                        <Divider delimiter=">" />
                        </>
                    }
                    
                    {(menu_data?.Parent_Menu !== null && menu_data?.Sub_Menu !== null && menu_data?.Secondary_Sub_Menu !== null) && <>
                        <Link to={`/${menu_data?.Parent_Menu?.Primary_URL}/${menu_data?.Sub_Menu?.Primary_URL}/${menu_data?.Secondary_Sub_Menu?.Primary_URL}/`} className="">
                            {menu_data?.Secondary_Sub_Menu.Page_Name}
                        </Link>
                        <Divider delimiter=">" />
                        </>
                    }

                    <span className="last-element">
                        {menu_data?.Page_Name}
                    </span>

                </div>
            </Container>
        </div>
    )
}

export default BreadCrumbs

import * as React from "react"

import Seo from "../components/seo"

// Temporary Data
import commonData from "../data/common-data.json"
import agentBuilding from "../data/agent-building.json"

// Components
import Header from "../components/Header"
import BreadCrumbs from "../components/BreadCrumbs"
import PropertyOverview from "../layout/AgentBuilding/PropertyOverview"
import Description from "../layout/AgentBuilding/Description"
import AdditionalInformation from "layout/AgentBuilding/AdditionalInformation"
import AvailableApartments from "layout/AgentBuilding/AvailableApartments"
import Footer from "../components/Footer"

const breadCrumbsData = [
    {
        name: 'Home',
        link: '#'
    },
    {
        name: 'Somerset Court, W8, Kensington',
        link: '#'
    }
];

const AgentBuilding = () => {
    return (
        <>
            <Seo title="Property Details" />
            <div className="page-wrapper">
                <Header headerData={ commonData } />
                <BreadCrumbs
                    breadCrumbsData={ breadCrumbsData }
                />
                <PropertyOverview />
                <Description />
                <AdditionalInformation />
                <AvailableApartments
                    availableApartmentsData={ agentBuilding.AvailableProperties }
                />
                <Footer
                    footerData={ commonData.FooterMenu }
                    footerTermsMenu={ commonData.FooterTermsMenu }
                    footerSocialLinks={ commonData.FooterSocialLinks }
                    footerCompanies={ commonData.FooterCompanies }
                />
            </div>
        </>
    )
}

export default AgentBuilding
